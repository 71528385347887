import { Box, Icon } from "@nubeteck/components";
import {
  Alert,
  Button,
  Collapse,
  Dropdown,
  Flex,
  Form,
  FormInstance,
  Image,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FlexStyled } from "../registration/register-confirmation/confirmation.styled";
import { ColumnsType } from "antd/es/table";
import { IActivity } from "src/Interfaces/teacherCourses";
import { Table } from "antd/lib";
import { CardStyled } from "../custom/cards/card.styled";
import {
  FlexFiltersContainer,
  MediumDrawer,
  SectionTitle,
} from "src/pages/teacher-pages/course-detail/course-detail.styled";
import { useNavigate } from "react-router-dom";
import { SmallHeadingStyled } from "../custom/texts/texts.styled";
import { NoResultsSVG } from "src/assets";
import TextArea from "antd/es/input/TextArea";
import {
  useCreateCourseActivityMutation,
  useCreateMoodleUrlMutation,
  useDeleteActivityMutation,
  useGetActivitiesFromMoodleMutation,
  useGetActivityByIdMutation,
  useGetActivityDefaultValuesMutation,
  useGetActivityTypesMutation,
  useUpdateActivityPointsMutation,
  useUpdateCourseActivityMutation,
} from "src/services";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { ArrayUtils } from "@nubeteck/utils";
import { editableComponents } from "../custom/editable-table-items/editable-table-items";
import { ButtonAlternativeStyled } from "../custom/buttons/buttons.styled";

function ActivitiesList({
  isLoading,
  data,
  seccionId,
  getData,
  editable,
}: {
  isLoading: boolean;
  data: { actividades: IActivity[]; totalPuntosPosibles: number };
  seccionId: number;
  editable: boolean;
  getData: ({
    seccionId,
    titulo,
    tipoId,
  }: {
    seccionId: number;
    titulo: string;
    tipoId: number;
  }) => void;
}) {
  const [drawerActivitiesOpen, setDrawerActivitiesOpen] = useState(false);
  const [actividadId, setActividadId] = useState(0);
  const [tituloFiltro, setTituloFiltro] = useState("");
  const [tipoIdFiltro, setTipoIdFiltro] = useState(0);
  const [actividadesPuntosPosibles, setActividadesPuntosPosibles] = useState<IActivity[]>([]);
  const [totalPuntosPosibles, setTotalPuntosPosibles] = useState(0);
  const [isEditingPuntosPosibles, setIsEditingPuntosPosibles] = useState(false);
  const [recursoMoodle, setRecursoMoodle] = useState("");
  const [oldMoodleActivityList, setOldMoodleActivityList] = useState([]);
  const [seccionMoodle, setSeccionMoodle] = useState(0);

  const isEditingRef = React.useRef(isEditingPuntosPosibles);

  const [
    createActivity,
    {
      isError: isErrorCreate,
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      error: errorCreate,
    },
  ] = useCreateCourseActivityMutation();

  const [
    updateActivity,
    {
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      isLoading: isLoadingUpdate,
      error: errorUpdate,
    },
  ] = useUpdateCourseActivityMutation();
  const [
    deleteCourseActivity,
    {
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      error: errorDelete,
    },
  ] = useDeleteActivityMutation();
  const [
    updatePoints,
    {
      isError: isErrorUpdatePoints,
      isSuccess: isSuccessUpdatePoints,
      isLoading: isLoadingUpdatePoints,
      error: errorUpdatePoints,
    },
  ] = useUpdateActivityPointsMutation();
  const [getActivityTypes, { data: activityTypes }] = useGetActivityTypesMutation();
  const [getActivityDefaultValues, { data: defaultValues }] = useGetActivityDefaultValuesMutation();
  const [getActivitiesMoodle, { data: activitiesMoodle, isLoading: isLoadingActivitiesMoodle }] =
    useGetActivitiesFromMoodleMutation();
  const [createMoodleUrl, { data: moodleUrl, isLoading: isLoadingMoodleUrl }] =
    useCreateMoodleUrlMutation();
  const [getActivityById, { data: activityById, isLoading: isLoadingById }] =
    useGetActivityByIdMutation();

  const [form] = Form.useForm<IActivity>();
  const formRef = React.useRef<FormInstance>(null);
  // const campoSiempreDisponible = Form.useWatch("esSiempreDisponible", form);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const tiposActividades = ArrayUtils.selectLabelValue(
    activityTypes ?? [],
    "actividadTipoNombre",
    "actividadTipoId",
  );

  const valoresDefaultTipos = ArrayUtils.selectLabelValue(
    defaultValues?.tipos,
    "actividadTipoNombre",
    "actividadTipoId",
  );
  const valoresDefaultRecursos = ArrayUtils.selectLabelValue(
    defaultValues?.recursos,
    "recursoNombre",
    "recursoId",
  );
  const valoresDefaultSemanas = ArrayUtils.selectLabelValue(
    defaultValues?.semanas,
    "semanaNombre",
    "semanaId",
  );
  const activitiesMoodleOptions = ArrayUtils.selectLabelValue(
    activitiesMoodle,
    "actividadMoodleNombre",
    "actividadMoodleId",
  );

  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldValue("actividadMoodleId", null);
    getActivitiesMoodle({ seccion: seccionMoodle, curso: seccionId, name: recursoMoodle });
    createMoodleUrl({ seccion: seccionMoodle, curso: seccionId, name: recursoMoodle });
  }, [seccionMoodle, recursoMoodle, getActivitiesMoodle, seccionId, createMoodleUrl, form]);

  useEffect(() => {
    if (activitiesMoodle?.length > oldMoodleActivityList?.length) {
      form.setFieldValue(
        "actividadMoodleId",
        activitiesMoodle[activitiesMoodle?.length - 1].actividadMoodleId,
      );
    }
  }, [activitiesMoodle, oldMoodleActivityList, form]);

  useEffect(() => {
    getData({ seccionId, titulo: tituloFiltro, tipoId: tipoIdFiltro });
  }, [
    getData,
    tipoIdFiltro,
    tituloFiltro,
    seccionId,
    isSuccessCreate,
    isSuccessUpdate,
    isSuccessUpdatePoints,
  ]);

  useEffect(() => {
    getActivityTypes();
    getActivityDefaultValues(seccionId);
  }, [getActivityTypes, getActivityDefaultValues, seccionId]);

  // keep the value in isToggledRef actual
  // when isToggled changes, isToggledRef is updated accordingly
  useEffect(() => {
    isEditingRef.current = isEditingPuntosPosibles;
  }, [isEditingPuntosPosibles]);

  useEffect(() => {
    setActividadesPuntosPosibles(data?.actividades);
  }, [data]);

  useEffect(() => {
    setTotalPuntosPosibles(data?.totalPuntosPosibles);
  }, [data]);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        getActivitiesMoodle({ seccion: seccionMoodle, curso: seccionId, name: recursoMoodle });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seccionMoodle, recursoMoodle]);

  useEffect(() => {
    if (actividadId && actividadId !== 0) {
      getActivityById(actividadId);
    }
  }, [actividadId, getActivityById]);

  useEffect(() => {
    if (activityById) {
      form.setFieldsValue(activityById);
      setDrawerActivitiesOpen(true);
    }
  }, [activityById, form]);

  useEffect(() => {
    setDrawerActivitiesOpen(false);
    if (isErrorCreate) toast.error(errorCreate?.data?.detail, toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
    }
    form.resetFields();
    setActividadId(0);
  }, [isSuccessCreate, form, isErrorCreate, errorCreate]);

  useEffect(() => {
    setDrawerActivitiesOpen(false);
    if (isErrorUpdate) toast.error(errorUpdate?.data?.detail, toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
    }
    setActividadId(0);
    form.resetFields();
  }, [isSuccessUpdate, form, isErrorUpdate, errorUpdate]);

  useEffect(() => {
    if (isErrorUpdatePoints) toast.error(errorUpdatePoints?.data?.detail, toastErrorStyle);

    if (isSuccessUpdatePoints) {
      toast.success("Puntos posibles actualizados exitósamente", toastSuccessStyle);
    }
  }, [isSuccessUpdatePoints, isErrorUpdatePoints, errorUpdatePoints]);

  useEffect(() => {
    if (isErrorDelete) toast.error(errorDelete?.data?.detail, toastErrorStyle);
    if (isSuccessDelete) {
      toast.success("Actividad removida exitósamente", toastSuccessStyle);
    }
  }, [isSuccessDelete, isErrorDelete, errorDelete]);

  // these values never change so the calllback is not going to be ever re-created
  const switchEditing = useCallback(
    () => setIsEditingPuntosPosibles(!isEditingRef.current),
    [isEditingRef, setIsEditingPuntosPosibles],
  );

  const handleSave = (row: IActivity) => {
    let tempArr = actividadesPuntosPosibles;

    tempArr = actividadesPuntosPosibles.map((act) =>
      act.actividadId !== row?.actividadId ? act : row,
    );
    setActividadesPuntosPosibles(tempArr);
  };

  const deleteActivity = (record: IActivity) => {
    modal.confirm({
      title: `¿Esta seguro(a) de que desea remover la actividad ${record.actividadNombre}?`,
      icon: <Icon name="" color="#FFC53D" />,
      okText: "Remover",
      onOk: () => {
        deleteCourseActivity(record.actividadId);
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const submitActivity = (d: IActivity) => {
    const data = { ...d, seccionId };
    if (actividadId) updateActivity({ ...data, id: actividadId });
    else createActivity(data);
    setActividadId(0);
  };

  const activitiesColumns = React.useMemo<ColumnsType<IActivity>>(
    () => [
      {
        title: "Título",
        dataIndex: "actividadNombre",
        width: 250,
      },
      {
        title: "Tipo de actividad",
        dataIndex: "actividadTipoNombre",
        width: 220,
      },
      {
        title: () =>
          editable ? (
            <FlexStyled>
              Puntos posibles{" "}
              <Button
                size="small"
                type="text"
                icon={<Icon name="" color="#A7A7A7" />}
                onClick={switchEditing}
              />
            </FlexStyled>
          ) : (
            "Puntos posibles"
          ),
        dataIndex: "puntosPosibles",
        width: 100,
        editable: true,
        fieldType: "number",
      },
      {
        title: "",
        dataIndex: "actionsGrades",
        fixed: "right",
        width: 30,
        render: (_, record) =>
          totalPuntosPosibles == 100 ? (
            <Button
              type="text"
              title="Calificaciones"
              icon={<Icon name="" outlined color="#A7A7A7" />}
              onClick={() =>
                navigate(`/teacher/courses/activity-grades/${record.actividadId}/${seccionId}`)
              }
            />
          ) : (
            "null"
          ),
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 30,
        render: (_, record) =>
          !record.estaCalificada && editable ? (
            <Dropdown
              overlayClassName="table-dropdown"
              className="cursor-pointer"
              key={record.actividadId}
              trigger={["click"]}
              align={{ offset: [-40, 4] }}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Editar",
                    icon: <Icon name="" color="#A7A7A7" />,
                    onClick: () => {
                      setActividadId(record.actividadId);
                    },
                  },
                  {
                    key: "2",
                    label: "Remover",
                    icon: <Icon name="" color="#A7A7A7" />,
                    onClick: () => deleteActivity(record),
                  },
                ],
              }}
            >
              <Icon name="" isTouchable />
            </Dropdown>
          ) : null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editable, totalPuntosPosibles],
  );
  const columns = activitiesColumns.map((col: any) => {
    return {
      ...col,
      onCell: (record: IActivity) => ({
        record,
        fieldType: col.fieldType,
        options: col.options,
        editable: col.editable && isEditingPuntosPosibles && !record.estaCalificada && editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <SectionTitle>Actividades</SectionTitle>
      <CardStyled>
        <FlexFiltersContainer>
          <Form.Item label="Tipo de actividad">
            <Select
              options={tiposActividades}
              allowClear
              placeholder="Tipo de actividad"
              onChange={(e) => setTipoIdFiltro(e)}
            />
          </Form.Item>
          <Form.Item label="Búsqueda de actividad">
            <Input
              allowClear
              placeholder="Búsqueda"
              onChange={(e) => setTituloFiltro(e.target.value)}
            />
          </Form.Item>
          <Button
            onClick={() => navigate(`/teacher/courses/course-grades/${seccionId}/grades-historial`)}
          >
            Historial de cambios de calificaciones
          </Button>
          {editable && (
            <Button
              type="primary"
              onClick={() => setDrawerActivitiesOpen(true)}
              loading={isLoadingCreate || isLoadingUpdate || isLoadingById || isLoadingUpdatePoints}
            >
              Nueva actividad
            </Button>
          )}
          {isEditingPuntosPosibles && (
            <Button
              onClick={() => updatePoints({ actividades: actividadesPuntosPosibles })}
              loading={isLoadingCreate || isLoadingUpdate || isLoadingById || isLoadingUpdatePoints}
            >
              Guardar cambios
            </Button>
          )}
        </FlexFiltersContainer>
        <Table<IActivity>
          components={editableComponents}
          locale={{
            emptyText: (
              <Box>
                <Box>
                  <SmallHeadingStyled>No se encontraron actividades</SmallHeadingStyled>
                  <Image
                    src={NoResultsSVG}
                    preview={false}
                    alt="Ilustración sin resultados"
                    width={200}
                  />
                </Box>
              </Box>
            ),
          }}
          pagination={false}
          size="small"
          loading={isLoading || isLoadingDelete || isLoadingUpdatePoints}
          columns={columns}
          dataSource={actividadesPuntosPosibles}
          rowKey={(v) => v.actividadId}
          scroll={{ x: 500 }}
        />

        <Flex
          style={{
            gap: "67%",
            padding: 10,
            margin: "5px 0px 15px 0px",
            color: "#00479B",
            fontWeight: "bold",
            backgroundColor: "#F4F6FA",
          }}
        >
          <span>PUNTOS TOTALES:</span>
          <span>{totalPuntosPosibles}</span>
        </Flex>
        {data?.actividades?.length > 0 && totalPuntosPosibles < 100 && (
          <Alert
            type="warning"
            message="La sumatoria de puntos posibles en las actividades no satisfacen el 100%"
            style={{ marginBottom: 5 }}
          />
        )}
      </CardStyled>
      <MediumDrawer
        placement="right"
        title={actividadId !== 0 ? "Edición de actividad" : "Nueva actividad"}
        open={drawerActivitiesOpen}
        size="large"
        onClose={() => {
          setActividadId(0);
          form.resetFields();
          setDrawerActivitiesOpen(false);
        }}
      >
        {" "}
        <Form
          form={form}
          layout="vertical"
          ref={formRef}
          onFinish={submitActivity}
          style={{ display: "flex", flexDirection: "column", gap: 16 }}
          initialValues={{
            id: 1,
          }}
        >
          <Collapse
            defaultActiveKey={[1]}
            activeKey={actividadId > 0 ? [1] : undefined}
            items={[
              {
                key: 1,
                label: (
                  <SmallHeadingStyled style={{ margin: 0 }}>Datos generales</SmallHeadingStyled>
                ),
                children: (
                  <div>
                    {" "}
                    <Form.Item
                      name="actividadNombre"
                      label="Título"
                      hasFeedback
                      required
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>
                    <Form.Item
                      name="tipoActividadId"
                      label="Tipo"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <Select
                        loading={isLoadingById}
                        options={valoresDefaultTipos}
                        allowClear
                        placeholder="Seleccionar tipo de actividad"
                      />
                    </Form.Item>
                    <Form.Item
                      name="descripcion"
                      label="Descripción"
                      hasFeedback
                      required
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                    <Form.Item
                      name="puntosPosibles"
                      label="Puntos posibles"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <InputNumber min={0} max={100} controls={false} />
                    </Form.Item>
                  </div>
                ),
              },
              {
                key: 2,
                collapsible: actividadId > 0 ? "disabled" : "header",
                label: (
                  <SmallHeadingStyled style={{ margin: 0 }}>Información LMS</SmallHeadingStyled>
                ),
                children: (
                  <div>
                    <Form.Item
                      name="tipoMoodleId"
                      label="Tipo de recurso"
                      required={actividadId == 0}
                      rules={[
                        {
                          required: actividadId == 0,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <Select
                        loading={isLoadingById}
                        options={valoresDefaultRecursos}
                        onChange={(e) => setRecursoMoodle(e)}
                        allowClear
                        placeholder="Seleccionar tipo de recurso"
                      />
                    </Form.Item>
                    <Form.Item
                      name="seccionMoodleId"
                      label="Sección/Semana"
                      required={actividadId == 0}
                      rules={[
                        {
                          required: actividadId == 0,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <Select
                        loading={isLoadingById}
                        options={valoresDefaultSemanas}
                        onChange={(e) => setSeccionMoodle(e)}
                        allowClear
                        placeholder="Seleccionar sección"
                      />
                    </Form.Item>
                    <Form.Item
                      name="actividadMoodleId"
                      label="Actividad a asociar"
                      required={actividadId == 0}
                      rules={[
                        {
                          required: actividadId == 0,
                          message: "Este campo es requerido.",
                        },
                      ]}
                    >
                      <Select
                        options={activitiesMoodleOptions}
                        placeholder="Seleccionar actividad"
                        filterOption={filterOption}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        loading={isLoadingActivitiesMoodle || isLoadingById}
                      />
                    </Form.Item>
                    <ButtonAlternativeStyled
                      size="small"
                      disabled={seccionMoodle == 0 || recursoMoodle == ""}
                      loading={isLoadingMoodleUrl}
                      onClick={() => {
                        setOldMoodleActivityList(activitiesMoodle);
                        window.open(moodleUrl);
                      }}
                    >
                      Crear actividad
                    </ButtonAlternativeStyled>
                  </div>
                ),
              },

              // {
              //   key: 4,
              //   label: (
              //     <SmallHeadingStyled style={{ margin: 0 }}>Disponibilidad</SmallHeadingStyled>
              //   ),
              //   children: (
              //     <div>
              //       {" "}
              //       <Form.Item name="esSiempreDisponible" valuePropName="checked" hasFeedback>
              //         <Checkbox checked>Siempre disponible</Checkbox>
              //       </Form.Item>
              //       {!campoSiempreDisponible ? (
              //         <Form.Item
              //           name="disponibilidad"
              //           label="Disponibilidad"
              //           hasFeedback
              //           required={!campoSiempreDisponible}
              //           rules={[
              //             {
              //               required: !campoSiempreDisponible,
              //               message: "Este campo es requerido.",
              //             },
              //           ]}
              //         >
              //           <DatePicker.RangePicker
              //             placeholder={["Fecha inicio", "Fecha fin"]}
              //             showTime={{ format: "HH:mm" }}
              //             format="YYYY-MM-DD HH:mm"
              //             onChange={(value, dateString) => {
              //               console.log("Selected Time: ", value);
              //               console.log("Formatted Selected Time: ", dateString);
              //             }}
              //           />
              //         </Form.Item>
              //       ) : null}
              //     </div>
              //   ),
              // },
            ]}
          ></Collapse>

          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ alignSelf: "flex-end" }}
            loading={isLoading || isLoadingCreate || isLoadingUpdate}
          >
            Guardar
          </Button>
        </Form>
      </MediumDrawer>
      {contextHolder}
    </>
  );
}

export default ActivitiesList;
